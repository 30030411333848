html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 100px;
  font-family: 'Open Sans', 'PT Sans', sans-serif;
  font-weight: 400;
  background: #f4f8f9;
}

body > .container {
  padding: 60px 15px 0;
}

table th{
  white-space: nowrap;
}

a:hover{
  text-decoration: none;
}

p{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5em;
}

span{
  font-size: 14px;
  font-weight: 400;
}


p strong{
  font-size: 16px;
  font-weight: bold;
}

.overflow{
  width: 100%;
  overflow-x: auto;
}

.overflow-y-hidden{
  overflow-y: hidden;
}

.nowrap{
  white-space: nowrap;
}

.road_train {
  margin-left: 0px;
  margin-right: 0px;
}

.road_train .inline{
  display: inline-block;
  width: 180px;
  white-space: normal;
  font-size: 14px;
  float: left;
}

.road_train .inline-input{
  display: inline-block;
  width: 140px;
  white-space: normal;
  text-align: right
}

.road_train .inline-input span{
  float: left;
  margin-top: 7px;
}

.road_train .inline-select{
  display: inline-block;
  width: 140px;
  padding-right: 20px;
}

.road_train .inline-input .form-group{
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 20px;
}


.wrap th{
  white-space: normal;
}


.round_icon{
  float: left;
  size: 50px;
  height: 45px !important;
  width: 45px !important;
  margin-right: 10px;
}

.blue{
  color: #0290ff !important;
}

.red{
  color: #e06764 !important;
}

.yellow{
  color: #fcbe51;
}

.yellow-bg{
  background-color: lightyellow !important;
}

.green{
  color: #87c992
}

.blue_icon{
  color: #0290ff;
}

.red_icon{
  color: #e06764;
}

.place_icons{
  margin-bottom: -7px;
  width: 20px !important;
}

.star_icon{
  height: 18px !important;
  margin-top: -3px;
}

.app_action button{
  text-transform: none;
  color: #323c47;
  font-size: 14px;
}

.app_action .col-md-6{
  padding-right: 0 !important;
}

.btn_tab{
  text-transform: none !important;

  border-radius: 5px 5px 0 0 !important;
  background-color: #ffffff;
  box-shadow: 0 -1px 5px 0 rgba(159, 163, 183, 0.5);
  margin-right: 10px !important;
  color: #323c47 !important;
}


.active_btn_tab{
  background: #0290ff !important;
  color: #fff !important;
}

.kyc{
  padding-bottom: 30px;
}

.logo-title{
  font-size: 24px;
  padding-top: 15px;
}

.logo-title-inner{
  font-size: 24px;
  padding-top: 15px;
  font-weight: 400;
  color: #1f549b;
}

.navbar .row{
  width: 100%;
}

.navbar-nav{
  display: inline-flex;
}

header{
  text-transform: uppercase;
}

header a{
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
}

header li{
  white-space: nowrap;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}

.cabinet-header li{
  color: #1f549b;
}

.flex{
  flex: 1;
}

.user_name{
  text-transform: none;
}

.full-width{
  width: 100%;
}

.lbutton{
  width: 230px;
}

.cabinet-content .btn{
  /* margin-left: 15px; */
}

.dialog-window div:last-child{
  max-height: 90vh;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f5f7f9;
  opacity: 1;
}

.blue_frame{
  border: 1px solid #0290ff;
  padding: 10px 20px 0px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.btn{
  background: #0290ff !important;
  border: 1px solid #0290ff !important;
  text-transform: normal;
  font-size: 14px;
  color: #ffffff !important;
}

.btn-reject{
  background-color: #ffffff !important;
  border: 1px solid #0290ff !important;
  text-transform: uppercase;
  font-size: 14px;
  color: #0290ff !important;
  height: 34px;
  border-radius: 3px;
/*
  border: 1px solid #0290ff !important;
  height: 43px;
  font-size: 14px !important;
  padding: 0 40px !important;
  font-weight: 400 !important;
  text-transform: uppercase;

  border-radius: 3px; */
}

.paste-container{
  border: 2px dashed darkgray;
  padding:20px;
}
.photo-option {
  cursor: pointer;
}
.photo-option-green {
  border: 2px solid greenyellow;
}
.btn-add{
  background: #0290ff !important;
  border: 1px solid #0290ff !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin-left: 15px !important;
}

.btn-add:disabled{
  background: #b7b7b7 !important;
  border: 1px solid #b7b7b7 !important;
}

.btn-add a{
  color: #ffffff !important;
}


.accept-btn{
  color: #ffffff !important;
  border: 1px solid #87c992 !important;
  text-transform: none !important;
  background-color: #87c992 !important;
  margin: 0 5px !important;
}

.decline-btn{
  color: #e89897 !important;
  border: 1px solid #e89897 !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin: 0 5px !important;
}

.info-btn{
  color: #0290ff !important;
  border: 1px solid #0290ff !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin-left: 15px !important;
}

.moreinfo-btn{
  color: #656565 !important;
  border: 1px solid #656565 !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  margin-left: 15px !important;
}

.more-btn{
  text-transform: none !important;
  background-color: #ffffff !important;
  margin-left: 15px !important;
}

.search-btn{
  height: 38px;
  width: 100%;
  border-radius: 0 5px 5px 0px;
}

.search-input{
  border-radius: 5px 0 0 5px;
}

.btn-red{
  background-color: #e26f6c !important;
  border: 1px solid #e26f6c !important;
  height: 43px;
  font-size: 14px !important;
  padding: 0 40px !important;
  font-weight: 200 !important;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 3px;
}

.inner-page .btn{
  margin-left: 0;
}

.date_style{
  font-size: 14px;
    padding-left: 5px;
    padding-right: 0;
}

.with_price{
  padding: 0;
}

.app_card{
  margin-bottom: 2px;
}

.cursor{
  cursor: pointer;
}

.card_overflow{
  overflow: unset !important;
  min-height: 400px;
}

.react-autosuggest__suggestions-container{
  height: 280px;
  overflow: auto;
}

.cabinet-header{
  background: #fafbfc;
  border-bottom: 1px solid #e7e9ea;
  margin-bottom: 10px;
}

.cabinet-header .navbar{
  padding-top:0;
  padding-bottom:0;
}

.cabinet-left-menu{
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.cabinet-content{
  background: #ffffff;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.cabinet-left-menu ul{
  padding: 0;
}

.cabinet-left-menu li{
  list-style: none;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #e9eff4;
  margin-bottom: 15px;
  padding: 5px 0;
}

.cabinet-left-menu li a{
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}

.cabinet-left-menu .active{
  border-radius: 5px;
  background-color: #0290ff;
  color: #ffffff !important;
}

.cabinet-left-menu li.active a{
  color: #ffffff !important;
}

.dialog{
  padding: 0 50px;
  min-width: 500px;
  overflow-y: visible !important;
}

.dialog_important {
  padding: 0 24px 0 !important;
  min-width: 500px !important;
  overflow-y: visible !important;
}
.UserModalPaper {
  overflow: visible !important;
}

.dialog h4{
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}

.dialog small{
  text-align: left;
  display: block;
  color: #868686
}

.dialog p{
  font-size: 14px;
}

.form_title{
  margin-bottom: -15px;
}

.menu-center{
  margin-top: 15px;
  text-align: center;
}

.menu-right{
  margin-top: 15px;
  text-align: right
}

.text-white{
  color: #ffffff;
}

.bg-light{
  background-color: transparent !important;
  color: #ffffff
}

.bg-light a{
  color: #ffffff
}

.btn-danger{
  background: #FF0000;
  text-transform: uppercase;
  padding: 7px 50px;
}

.btn-black{
  background: #000000;
  color: #ffffff;
  text-transform: uppercase;
  padding: 7px 50px;
}

.btn-white{
  background: white;
}

.login-bg{
  background: url('./img/full-bg.png')  top center no-repeat;
  background-size: cover;
  background-position: 20%;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 70px;
}

.bg-yellow-fon header{
  color: white;
}

.logo{
  width: 50px;
}

.bold-text{
  font-weight: 700;
}

.role-div{
  padding: 30px 15px 20px;
  border-top: 1px solid #ccc;
}

.role-div span{
  margin-right: 20px;
  padding-bottom: 20px;
  color: #949494;
}

.active_role{
  border-bottom: solid 2px #0290ff;
  color: black !important;
}

.tc_type{
  padding: 0px 0px 0px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.tc_type span{
  margin-right: 20px;
  padding-bottom: 20px;
  color: #949494;
  font-weight: 400;
  display: inline-block;
}

.active_tc{
  border-bottom: solid 2px #0290ff;
  color: black !important;
}


.welcome{
  margin-top: 50px;
  font-size: 46px;
  line-height: 50px;
  margin-bottom: 30px;
  font-weight: 300;
  position: relative;
}

.login{
  width: 218px;
  height: 45px;
  border-radius: 8px;
  font-size: 16px !important;
  background-image: linear-gradient(103deg, #7dbbff, #4786ff);
}


.file_models{
  display: inline-block;
}

.file_models .info-btn{
  margin: 0;
}

.file_models .info-btn{
  height: 30px;
  text-transform: none;
  width: 140px;
  box-shadow: 0 0 0 #fff;
}

.file_models .file_input{
  width: 100%;
  padding-left: 31px;
  color: #0290ff;
  margin-top: 5px;
  visibility: hidden;
}

.file_input{
  width: 100%;
  padding-left: 61px;
  color: #0290ff;
  margin-top: 5px;
}

input.file_input {
  opacity: 0;
  z-index: -1;
}

.file_btn{
  margin-top: -35px;
  display: block;
}

.file_btn .btn{
  margin: 0;
}

.file_btn .btn{
  height: 30px;
  text-transform: none;
  width: 200px
}

.skat .form-group{
  margin-bottom: 0;
}

.skat p{
  margin-bottom: 0 !important;
}

.privilege_status{
  background-color: #f5f7f9;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-weight: 400;
  min-height: 38px;
  color: #495057
}

.step_dates_card{
  padding-bottom: 15px !important;
}

.about_system{
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  padding: 1em;
}

.margin-bottom-10{
  margin-bottom: 10px !important;
}

.menu {
  margin-bottom: 30px;
  display: inline-block
}

.menu li{
  display: inline-block;
  margin-right: 20px;
}

.input-style{
  border: 0 !important;
  border-radius: 4px !important;
  padding: 10px 0px !important;
  width: 100%;
}

.input-style div{
  background: #ffffff !important;
  border: 0 !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
}

.input-style input{
  background-color: #ffffff !important;
  width: 100%;
}

.input-style p{
  color: #ffffff;
  font-size: 15px;
}

.input-style svg{
  fill: #d1d1d1;
}

.mini-input{
  width: 100px;
}

.full-input{
  width: 100%;
}

.label-form{
  margin-top: 5px;
  font-size: 14px;
}

.success{
  color: #6dbd7b !important;
}

.danger{
  color: #dc7575 !important;
}

.cabinet_pass{
  border: 0!important;
  border-radius: 4px !important;
  padding: 0px 0px 15px !important;
  width: 100%;
}

.cabinet_pass div{
  background: #ffffff !important;
  border: 1px solid #ccc ;
  border-radius: 4px !important;
  padding: 5px 10px !important;
}

.cabinet_pass input{
  background-color: #ffffff !important;
  width: 100%;
}

.cabinet_pass p{
  color: #dc3545!important;
  font-size: 14px;
}

.cabinet_pass svg{
  fill: #d1d1d1;
}

.no_border{
  border: 0 !important;
}

.border{
  display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #f2f2f2;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.border-mini{
  display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    width: 100px;
    background-color: #f2f2f2;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.sizes{
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_pagination{
  position: relative;
}

.table_pagination td{
  display: flex;
  position: sticky;
  right: 0;
}

.input{
  border: 1px solid #ccc;
}

.footer a{
  color: #ffffff;
  padding-right: 10px;
  font-size: 14px;
  text-decoration: underline
}

.forgot-links{
  margin-top: 20px;
}

.forgot-links span{
  cursor: pointer;
}

.cabinet-content{
  text-align: left
}

.checklogin span{
  color: #ffffff;
}

.checklogin svg{
  fill: #ffffff;
  width: 30px !important;
  margin-right: 10px !important;
}

.delete_file{
  position: absolute
}

.delete_tc{
  margin-top: 7px;
}

.form_input{
  margin-top: 15px;
}

.checkbox_left{
  float: left;
  margin-left: 5px !important;
  width: 150px
}

.checkbox_table{
  margin-top: 5px;
  margin-bottom: -10px;
}

.checkbox-div p{
  margin: 0;
}

.checkbox svg{
  fill: #0290ff;
  width: 30px !important;
}

.checkbox_filter {
  height: 20px !important;
  margin-bottom: 0px;
  margin-left: 7px  !important;
}

.checkbox_filter svg{
  fill: #0290ff;
  width: 30px !important;
}

.checkbox-table{

}

.checkbox-table svg{
  fill: #0290ff;
  width: 30px !important;
}


.has-error span{
  color: #dc3545!important;
  font-size: 14px;
}

.small-error {
  color: #dc3545!important;
  font-size: 10px !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #000000;
  color: #ffffff;
  padding: 15px 30px;
}

.footer .row:first-child{
  margin: 0 -30px;
  background-color: #000000;
}

.h2-title{
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #323c47;
  margin-bottom: 30px;
}

.h3-title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #323c47;
  margin-top: 20px;
}

.h4-title{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #323c47;
  margin-top: 10px;
}

.no-margin {
  margin: 0;
}

.help_icon{
  margin-bottom: -6px;
  color: #0290ff;
  width: 18px !important;
}

table{
  border-top: 1px solid  rgba(159, 163, 183, 0.5);
}

table th, table td{
  padding: 0 10px !important;
}

.tab_style button{
  border-radius: 5px 5px 0px 0px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px 0 rgba(159, 163, 183, 0.5);
  margin-right: 10px;
  margin-left: 1px;
  margin-top: 5px;
  height: 46px;
  min-width: auto;
  text-transform: none;
  border: 0 !important;
}

.tab_style_inner {
  background: #ffffff;
}

.tab_style_inner button{
  background-color: #ffffff;
  margin-right: 10px;
  margin-left: 1px;
  margin-top: 5px;
  height: 46px;
  max-width: 210px;
  text-transform: none;
  border: 0 !important;
}
.tab_style_inner button span{
  font-size: 14px !important;
}

.tab_style_inner .tab_active{
  background: #ffffff !important;
  color: #000000 !important;
  border: 0 !important;
}

.paper{
  min-height: 50px;
}

.indicator {
  background-color: #1890ff;
  border: 0;
}

.tab_style div{
  box-shadow: 0 0 0 !important;
  border: 0 !important;
}

.tab_style span{
border: 0 !important;
}

.tabs_header{
  box-shadow: 0 0 0  !important;
}

.tab_active{
  background: #0290ff !important;
  color: #ffffff !important;
  /* border-bottom: 2px solid #4da1ff !important */
}

.tab_style{
  background: #ffffff;
  border: 0 !important;
}

.MuiDialog-paper-102{
  max-height: 90vh;
}

.car-img{
  height: 200px;
  margin-left: 40px;
  margin-bottom: 60px
}

.car-block-front{
  display: inline-block;
  position: relative;
  padding: 0px 0 0px 0px;
}

.car_height{
  position: absolute;
  left: 0;
  top:0;
  height: calc(100% - 60px);
  padding-right: 5px;
  padding-top: 45%;
}

.arrow_top{
  color: red;
  position: absolute;
  top: -8px;
  left: 15px;
}

.arrow_bottom{
  color: red;
  position: absolute;
  bottom: -10px;
  left: 15px;
}

.vertical_line{
  border-right: 2px solid red;
  height: 100%;
  position: absolute;
  top: 0;
  left: 26px;
}

.car_width{
  position: absolute;
  left: 40px;
  bottom: 10px;
  width: calc(100% - 40px);
  text-align: center;
}

.arrow_left{
  color: red;
  position: absolute;
  top: -11px;
  left: -10px;
}

.arrow_right{
  color: red;
  position: absolute;
  top: -11px;
  right: -10px;
}

.horizontal_line{
  border-top: 2px solid red;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.car-block-side{
  display: inline-block;
  position: relative;
  padding: 0px 20px 0px 0;
}

.car_length{
  position: absolute;
  text-align: center;
  bottom: 10px;
}

.car_axle{
  position: absolute;
  bottom: 10px;
  margin-left: -12px;
}

.car_axle svg{
  color: red;
  display: block
}

.car_spacing{
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.close_user{
  margin-bottom: -40px;
  margin-top: -10px;
  margin-right: -10px;
}

/* Steps */

.steps{
  margin-bottom: 2em;
}

.steps hr{
  border: 1px solid rgba(176, 180, 184, 0.5);
}
.steps a{
  color: #b0b4b8;
  font-size: 16px;
  font-weight: 500;
}

.active_step a{
  color: #0290ff;
  font-size: 16px;
  font-weight: 500;
}

.active_step hr{
  border: 1px solid #0290ff;
}

.points_overflow{
  height: 400px;
  overflow: auto !important;
}

.car-character-block{

  margin-top: -100px;
}

.car-character-inner{
  padding-top: 100px;
}

.car-character{
  height: 200px;
  margin-left: 10px;
  margin-bottom: 60px
}

.points{
  margin-top: 0px;
}

.points input{
  padding: 20px 10px;
  width: 100%;
}

.points svg{
  margin-top: 10px;
  color: #0290ff;
}

.map-point {
  z-index: 1;
  background: white;
}

.map-point svg {
  color: #0290ff;
}

.points-date input{
  margin-bottom: 15px;
  padding: 10px 10px;
  width: 100%;
}

.points-date svg{
  margin-top: 10px;
  color: #0290ff;
  background: #fff;
}

.points_area{
  position: relative;
}

.points_line{
  width: 1px;
  height: 100%;
  border-right: 1px dashed #ccc;
}

.points_div{
  padding-top: 20px;
  padding-bottom: 30px;
  height: 100%;
  position: absolute;
  left: 12px;
}

.points_div.points_line-map {
  left: 16px;
}

.select-type{
  width:100px;
  padding: 15px 0 0 !important;
}

.select-type input{
  width:80px;
}

@media (max-width: 768px){

  body{
    margin-bottom: 0;
  }

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: #000000;
    color: #ffffff;
    padding: 30px;
  }

  .menu-right{
    text-align: center;
    margin-top: 0;
  }

  .navbar .row{
    width: auto
  }

  .navbar-nav{
    display: block;
  }

  .navbar-nav li{
    display: inline-block;
    padding: 0 10px;
  }

  .welcome{
    font-size: 28px;
    margin: 10px 0;
    line-height: 40px;
  }

  .dialog{
    min-width: auto;
  }

  .lbutton{
    width: auto;
  }

}

.table_select .form-group{
  margin-bottom: 0;
}

.table_select select{
  width: 100px;
}

.pagination_block td{
  padding: 10px 20px !important;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}


.no-margin{
  margin-left: 0 !important;
  margin-right: 5px !important;
}

.no-margin-left{
  margin-left: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-left{
  margin-left: 16px !important;
}

.back a{
  /* text-decoration: underline; */
  font-size: 20px;
}
.back {
  margin-top: 50px;
}

.back svg{
  margin-bottom: -6px;
}

.control_post{
  width: 350px;
  margin: 0 auto;
}

.margin_1{
  margin-left: 0px !important;
}

.margin_2{
  margin-left: 20px !important;
}
p.margin_2{
  font-weight: 800;
  margin-left: 50px !important;
}

.spring .checkbox{
  height: 30px;
}

.weight_info input{
  display: inline;
  margin-right: 10px;
  float: left;
}

.weight_info span{
  display: block;
  line-height: 18px !important;
}

button.reset-transform {
  text-transform: initial;
}

button.disabled-button {
  filter: grayscale(1);
  opacity: 0.6;
}

.full-height {
  height: 100%;
}

.full-screen-loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-file-input {
  width: 0;
  height: 0;
}

label.radio {
  margin-bottom: 0;
}

.input-link-container {
  position: relative;
}

.margin-top {
  margin-top: 16px !important;
}

.input-link {
  position: absolute;
  width: 100%;
  height: 100%;
}

.application-container {
  position: relative;
}

.application-header {
  z-index: 100000;
  margin: 0 0px;
  position: sticky;
  z-index: 1010;
  top: 0;
  padding: 20px 0;
  background-color: white;
}

.leaflet-routing-container  {
  display: none !important;
}

.application-full-page-loader {
  z-index: 10000110;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
}

.application-full-page-loader-content {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  height: 100%;
  margin-left: 25%;
  left: 0;
  top: 0;
}

.new-application-container {
  padding-bottom: 40px;
}

.react-select-container_multiline {
  height: 100px;
}

.react-select_multiline__control {
  height: 100px;
}

.react-select_multiline__value-container {
  height: 100px;
}

.react-select_multiline__control {
  height: auto;
}

.react-select_multiline__single-value {
  white-space: pre-wrap !important;
  height: 100px;
  display: flex;
  align-items: center;
}

.inline-form-input {
  display: inline-block;
  width: 50%;
}

.apvgk-sloppiness
{
  z-index: 1200;
}